exports.components = {
  "component---src-pages-3-pl-brokers-js": () => import("./../../../src/pages/3pl-brokers.js" /* webpackChunkName: "component---src-pages-3-pl-brokers-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carriers-js": () => import("./../../../src/pages/carriers.js" /* webpackChunkName: "component---src-pages-carriers-js" */),
  "component---src-pages-distributionandmanufacturing-js": () => import("./../../../src/pages/distributionandmanufacturing.js" /* webpackChunkName: "component---src-pages-distributionandmanufacturing-js" */),
  "component---src-pages-free-assessment-js": () => import("./../../../src/pages/free-assessment.js" /* webpackChunkName: "component---src-pages-free-assessment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */)
}

